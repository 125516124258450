// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-commission-js": () => import("./../../../src/pages/commission.js" /* webpackChunkName: "component---src-pages-commission-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paint-js": () => import("./../../../src/pages/paint.js" /* webpackChunkName: "component---src-pages-paint-js" */),
  "component---src-pages-play-js": () => import("./../../../src/pages/play.js" /* webpackChunkName: "component---src-pages-play-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-wall-js": () => import("./../../../src/pages/wall.js" /* webpackChunkName: "component---src-pages-wall-js" */),
  "component---src-templates-paint-collection-js": () => import("./../../../src/templates/paint-collection.js" /* webpackChunkName: "component---src-templates-paint-collection-js" */),
  "component---src-templates-play-project-js": () => import("./../../../src/templates/play-project.js" /* webpackChunkName: "component---src-templates-play-project-js" */),
  "component---src-templates-wall-project-js": () => import("./../../../src/templates/wall-project.js" /* webpackChunkName: "component---src-templates-wall-project-js" */)
}

